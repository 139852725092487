<template>
  <div class="form-stepper form-stepper-feed">

    <Loading :value="loading" color="#f0f0f0" />

    <v-form
      ref="form"
      v-model="valid"
      class="pa-6 scroll"
      @submit.prevent
    >
      <p>{{ $t('FEED.PROTOCOL_TEXT') }}</p>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="protocol" class="mt-0">
            <v-radio
              v-for="(item, i) in protocolItems"
              :key="'prot'+i"
              :label="item.text"
              :value="item.value"
            />
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- HTTP -->
      <div v-if="protocol === 'http'">
        <p class="mt-4">{{ $t('FEED.PROTOCOL_PARAMS_TEXT') }}</p>
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="protocol_params.url"
              :label="$t('INPUT.URL')"
              :placeholder="$t('INPUT.URL')"
              :rules="[$rules.required]"
              @keyup.enter="validate"
              outlined
              required
              dense
            />
          </v-col>
          <v-col cols="3" class="pb-0">
            <v-select
              v-model="protocol_params.method"
              :items="protocolMethodItems"
              :label="$t('INPUT.PROTOCOL_METHOD')"
              :placeholder="$t('INPUT.PROTOCOL_METHOD')"
              :rules="[$rules.required]"
              required
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-0">
          <v-col cols="12" class="pt-0 pb-0">
            <v-checkbox
              class="mt-0"
              v-model="protocolNeedAuth"
              dense
              :label="$t('INPUT.NEED_AUTH')"
              :placeholder="$t('INPUT.NEED_AUTH')"
            />
          </v-col>
        </v-row>

        <template v-if="protocolNeedAuth">
          <v-row class="mt-0">
            <v-col cols="6" class="pt-0 pb-0">
              <v-text-field
                v-model="protocol_params.user"
                :label="$t('INPUT.USER')"
                :placeholder="$t('INPUT.USER')"
                clearable
                outlined
                dense
              />
            </v-col>
            <v-col cols="6" class="pt-0 pb-0">
              <v-text-field
                v-model="protocol_params.password"
                :label="$t('INPUT.PASSWORD')"
                :placeholder="$t('INPUT.PASSWORD')"
                type="password"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>
          <p>{{ $t('COMMON.OR') }}</p>
          <v-row class="mt-0 mb-0">
            <v-col cols="12" class="pt-0 pb-0">
              <v-text-field
                v-model="protocol_params.auth"
                :label="$t('INPUT.AUTH_TOKEN')"
                :placeholder="$t('INPUT.AUTH_TOKEN')"
                @keyup.enter="validate"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>
        </template>

        <v-row class="mt-0 mb-0">
          <v-col cols="12" class="pt-0 pb-0">
            <v-checkbox
              class="mt-0"
              v-model="protocolNeedData"
              dense
              :label="$t('INPUT.NEED_DATA')"
              :placeholder="$t('INPUT.NEED_DATA')"
            />
          </v-col>
        </v-row>
        <template v-if="protocolNeedData">
          <v-row class="mt-0 mb-0">
            <v-col cols="12" class="pt-0 pb-0">
              <v-textarea
                v-model="protocol_params.data"
                :label="$t('INPUT.QUERY_DATA')"
                :placeholder="$t('INPUT.QUERY_DATA')"
                @keyup.enter="validate"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </template>

      </div>

      <!-- FTP -->
      <div v-if="protocol === 'ftp'">
        <p class="mt-4">{{ $t('FEED.PROTOCOL_PARAMS_TEXT') }}</p>
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="protocol_params.host"
              :label="$t('INPUT.HOST')"
              :placeholder="$t('INPUT.HOST')"
              @keyup.enter="validate"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="protocol_params.port"
              type="number"
              maxlength="5"
              :label="$t('INPUT.PORT')"
              :placeholder="$t('INPUT.PORT')"
              @keyup.enter="validate"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-text-field
              v-model="protocol_params.path"
              :label="$t('INPUT.FILE_PATH')"
              :placeholder="$t('INPUT.FILE_PATH')"
              @keyup.enter="validate"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-checkbox
              class="mt-0"
              v-model="protocol_params.secure"
              :label="$t('INPUT.SECURE')"
              :placeholder="$t('INPUT.SECURE')"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-0" v-if="protocol_params.secure">
          <v-col cols="12" class="pt-0 mt-0">
            <v-textarea
              v-model="protocol_params.secureOptions"
              :label="$t('INPUT.SECURE_OPTION')"
              :placeholder="$t('INPUT.SECURE_OPTION')"
              :disabled="!protocol_params.secure"
              outlined
              hide-details
              dense
            />
          </v-col>
        </v-row>
      </div>

      <!-- Upload -->
      <div v-if="protocol === 'upload'">
        <p class="mt-4 mb-0">{{ $t('FEED.PROTOCOL_UPLOAD_TEXT') }}</p>
        <small class="grey--text">{{ $t('FEED.PROTOCOL_PARAMS_FILE_TYPE_WARNING') }}</small>
        <v-row class="ma-0 pt-4" v-if="protocol_params && protocol_params.filename && protocol === 'upload'">
          <v-col cols="12" class="pa-0" >
            <v-list-item>
              <v-list-item-avatar class="primary">
                <v-icon size="24" color="white">mdi-file</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{protocol_params.filename}}</v-list-item-title>
                <v-list-item-subtitle><small><a :href="protocol_params.url" target="_blank">{{protocol_params.url}}</a></small></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="text-nowrap">
                  <v-btn class="ml-2" icon @click="removeFiles"><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="ma-0" v-else>
          <v-col cols="10" class="pl-0">
            <InputDropzone v-model="files" @change="uploadFiles">
              <div class="text-center"><v-icon size="42" color="primary">mdi-download</v-icon></div>
              <h4 class="primary--text">{{ $t('FEED.PROTOCOL_DROPZONE_TEXT_1') }}</h4>
              <small class="subtitle">{{ $t('FEED.PROTOCOL_DROPZONE_TEXT_2') }}</small>
            </InputDropzone>
          </v-col>
        </v-row>

        <div class="mt-4" v-if="fileError">
          <v-alert
            dense
            text
            type="error"
          >
            {{ $t('FEED.FILE_ERROR') }}
            <a @click="validate">{{$t('FEED.TEST_FILE_AGAIN')}}</a>
          </v-alert>
        </div>
      </div>

      <!-- API -->
      <div v-if="protocol === 'api'">
        <p class="mt-4 mb-0">{{ $t('FEED.PROTOCOL_API_TEXT') }}</p>
        <v-row class="ma-0 pt-4">
          <v-col cols="4" class="pa-0" >
            <v-select
              v-model="protocol_params.controller_name"
              :items="apiControllerNames"
              :label="$t('FEED.FIELD_PROTOCOL_API_CONTROLLER_NAME')"
              :rules="[$rules.required]"
              required
              hide-details
              outlined
              large
            >
            <template slot="selection" slot-scope="data">
              <img :src="getApiImage(data.item.value)" width="50px" class="mr-4" /> {{ data.item.text }}
            </template>
            <template slot="item" slot-scope="data">
              <img :src="getApiImage(data.item.value)" width="50px" class="mr-4" /> {{ data.item.text }}
            </template>
            </v-select>
          </v-col>
        </v-row>

        <div v-if="protocol_params.controller_name === 'Cegid'">

          <v-row class="ma-0 pt-4">
            <v-col cols="8" class="pa-0" >
              <v-text-field
                v-model="protocol_params.base_url"
                label="API URL Endpoint"
                placeholder="https://www.myapi.com/"
                @keyup.enter="validate"
                :rules="[$rules.required]"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>

          <v-row class="ma-0 pt-4">
            <v-col cols="4" class="pa-0 pr-4" >
              <v-text-field
                v-model="protocol_params.client_id"
                label="Client_id"
                placeholder="Client_id"
                @keyup.enter="validate"
                :rules="[$rules.required]"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="4" class="pa-0" >
              <v-text-field
                v-model="protocol_params.client_secret"
                type="password"
                label="Secret"
                placeholder="Secret"
                @keyup.enter="validate"
                :rules="[$rules.required]"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>

        </div>

      </div>


    </v-form>

    <div>
      <v-divider />
      <v-toolbar
        color="transparent"
        flat
        height="70px"
        class="form-footer"
      >
        <v-spacer/>
        <v-btn
          color="primary custom"
          class="mr-4"
          rounded
          depressed
          :disabled="!valid && !loading"
          large
          @click="validate"
        >
          <strong>{{ $t('COMMON.CONTINUE') }}</strong>
          <v-icon class="ml-4">mdi-arrow-right</v-icon>
        </v-btn>
      </v-toolbar>
    </div>

  </div>
</template>

<script>
import InputDropzone from '@/components/InputDropzone'
import Loading from '@/components/Loading'

export default {
  name: "FormFeedStepperProtocol",
  components: {
    Loading,
    InputDropzone
  },
  data: (_this) => ({
    loading: false,
    valid: false,
    dialog: false,
    protocolNeedData: false,
    protocolNeedAuth: false,
    protocolItems: [
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_HTTP'), value: 'http'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_FTP'), value: 'ftp'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_UPLOAD'), value: 'upload'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_API'), value: 'api'},
    ],
    protocolMethodItems: [
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_GET'), value: 'get'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_POST'), value: 'post'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_PUT'), value: 'put'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_PATCH'), value: 'patch'},
    ],
    dropzoneOptions: {
      // header
      acceptedFiles: '.xml,.csv,.json',
      thumbnailWidth: 150,
      maxFilesize: 350*1024*1024,
      maxFiles: 1,
    },
    file: null,
    delayAfterFileUpload: 2000,
    protocol: 'http',
    protocol_params: {method:'get'},
    files: [],
    fileError: false,
    apiControllerNames: [{text:"Cegid (TalentSoft)", value:"Cegid"}]
  }),
  mounted () {
    this.init()
  },
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  methods: {

    getApiImage(controller_name, size = 'original') {
      const url = `${process.env.VUE_APP_ASSETS}/apis/`
      return `${url + controller_name.toLowerCase()}.png`
    },

    removeFiles () {
      this.protocol_params = {}
    },

    uploadFiles () {
      this.fileError = false
      this.loading = true
      const values = {files:this.files}
      this.$services.api_programmatic.feed.upload({values})
        .then(response => {
          if (response.data) {
            const keys = Object.keys(response.data)
            if (keys.length > 0) {
              const filename = keys[0]
              const url = process.env.VUE_APP_FILESHARE_PUBLIC + '/' + response.data[keys[0]] // full url
              this.protocol_params = {filename, url}
              this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
            }
          } else {
            this.$store.dispatch('messenger/add', {type: 'warning', code: 'MESSAGE.FEED.INVALID_FILE', message: 'Uploaded file is empty or not valid.', time: 3000 })
          }
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    init () {
      Object.keys(this.values)
        .forEach((key) => {
          if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
            this[key] = this.values[key]
          }
        })
    },

    sendingEvent (file, xhr, formData) {
      console.warning('sendingEvent', file, xhr, formData)
    },

    loadFile (...args) {
      console.warning('loadFile', ...args)
    },

    validate() {

      if (!this.$refs.form.validate()) {
        this.$emit('validation', false)
        return
      }

      if (!this.file_content) {
        if (['http', 'ftp', 'upload', 'api'].indexOf(this.protocol) !== -1) {

          const data = {
            protocol_params: this.protocol_params,
            protocol: this.protocol,
          }

          this.loading = true
          this.$services.api_programmatic.feed.test({
            values: {
              protocol: this.protocol,
              protocol_params: this.protocol_params
            }
          })
            .then(response => {
              this.fileError = false
              if (response.data) {
                data.fileformat_columns = response.data.columns
                data.fileformat_list = response.data.list instanceof Array ? response.data.list[0] : response.data.list
                data.fileformat = response.data.format
                data.count = response.data.count
                data.examples = response.data.dataList
                data.protocol = this.protocol || undefined
                data.protocol_params = this.protocol_params || undefined
                data.protocolNeedAuth = this.protocolNeedAuth || undefined
                this.$emit('childData', data)
                this.$emit('validation', true)
                this.$emit('next')
              }
            })
            .catch(e => {
              console.log(e)
              this.fileError = true
            })
            .finally(() => {
              this.loading = false
            })
        }
        return false
      }
    }
  }
}
</script>
